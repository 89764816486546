import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { tsParticles } from 'tsparticles-engine';

window.showConfetti = async () => {
  await loadConfettiPreset(tsParticles); // this is required only if you are not using the bundle script

  await tsParticles.load('tsparticles', {
    preset: 'confetti',
  });
};

document.addEventListener('livewire:initialized', () => {
  window.Livewire.on('showConfetti', () => {
    window.showConfetti();
  });
});
