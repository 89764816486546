import 'livewire-sortable';
// import Clipboard from 'clipboard';
import $t from './lang';
import './utils/confetti';
import './loader';
import Vapor from 'laravel-vapor/src/index.js';
import * as Sentry from '@sentry/browser';

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
window.Vapor = Vapor;
window.$t = $t;

window.copyToClipboard = (e) => {
  const clipboardText = e.target.dataset.clipboardText || e.target.parentElement.dataset.clipboardText;
  const successMessage = e.target.dataset.successMessage || e.target.parentElement.dataset.successMessage;
  navigator.clipboard.writeText(clipboardText);
  new window.FilamentNotification()
    .success()
    .body(successMessage || $t('shared.copied_text_to_clipboard', { text: clipboardText }))
    .send();
};

document.addEventListener('livewire:init', () => {
  window.Livewire.on('openInNewTab', (args) => {
    window.open(args[0].url, '_blank');
  });
});

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    replaysSessionSampleRate: import.meta.env.PROD === 'production' ? 0.3 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration(),
    ],
  });
}
