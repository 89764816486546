import NProgress from 'nprogress';

NProgress.configure({ minimum: 0.1, showSpinner: false, trickleSpeed: 200 });

window.nprogress = NProgress;
document.addEventListener('livewire:initialized', () => {
  let progressBarTimeout = null;

  const clearProgressBarTimeout = () => {
    if (progressBarTimeout) {
      clearTimeout(progressBarTimeout);
      progressBarTimeout = null;
    }
  };

  const startProgressBar = () => {
    clearProgressBarTimeout();
    progressBarTimeout = setTimeout(() => {
      window.nprogress.start();
    }, 200);
  };

  const stopProgressBar = () => {
    clearProgressBarTimeout();
    window.nprogress.done();
  };

  window.Livewire.hook('commit', ({ component, respond }) => {
    if (['components.locker'].includes(component.name)) {
      return;
    }

    startProgressBar();

    respond(() => {
      stopProgressBar();
    });
  });
});
